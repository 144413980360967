import {ComponentRef, Directive, ElementRef, Input, Type, ViewContainerRef} from '@angular/core';
import {IDynamicComponent, IDynamicComponentData, IDynamicComponentLoading} from '@shared/shared.interfaces';
import {SafeResourceUrl} from '@angular/platform-browser';
import {MockElementRef} from '@shared/form/form.fixtures';
import {Observable} from 'rxjs';
import {HTMLElementTagNameConst} from '@shared/constants';

export const getMockBeforeUnloadEvent = (data = {}): BeforeUnloadEvent => ({...getMockEvent(), ...data} as unknown as BeforeUnloadEvent);

export const getMockCanvasRenderingContext2D = (suffix = 'canvas-rendering-context-2D'): CanvasRenderingContext2D => ({
    fillStyle: 'fillStyle-' + suffix,
    clearRect: () => {
    },
    measureText: () => ({width: 6} as TextMetrics),
    fillRect: () => {
    },
    fillText: () => {
    },
} as unknown as CanvasRenderingContext2D);

export const getMockComponentRef = <T = unknown>(init?: { instance: unknown }): ComponentRef<T> => ({
    ...{instance: {}, location: {nativeElement: getMockHTMLElement()}},
    ...init
} as ComponentRef<T>);

export const getMockDynamicComponent = (): IDynamicComponent => ({data: getMockDynamicComponentData()});

// @todo Faire le tour des appels et passer le suffix en paramètre
export const getMockDynamicComponentData = (suffix = 'dynamic-component-data'): IDynamicComponentData => ({uuid: 'uuid-' + suffix});

export const getMockDynamicComponentLoading = (): IDynamicComponentLoading => ({
    component: getMockDynamicComponent() as unknown as Type<IDynamicComponent>,
    data: getMockDynamicComponentData(),
});

// @todo Vérifier les utilisations et préférer getMockHTML*()
export const getMockElement = (): Element => ({
    appendChild: () => {
    },
    contains: () => {
    },
    getAttribute: () => {
    },
    getBoundingClientRect: () => {
    },
    querySelectorAll: () => {
    },
    scrollIntoView: () => {
    },
    setAttribute: () => {
    },
} as unknown as Element);

export const getMockElementRef = <T>(typeNativeElement = 'deprecated'): ElementRef<T> => {
    switch (typeNativeElement) {
        case 'anchor' :
            return new ElementRef(getMockHTMLAnchorElement()) as unknown as ElementRef<T>;
        case 'button' :
            return new ElementRef(getMockHTMLButtonElement()) as unknown as ElementRef<T>;
        case 'canvas' :
            return new ElementRef(getMockHTMLCanvasElement()) as unknown as ElementRef<T>;
        case 'div' :
            return new ElementRef(getMockHTMLDivElement()) as unknown as ElementRef<T>;
        case 'image' :
            return new ElementRef(getMockHTMLImageElement()) as unknown as ElementRef<T>;
        case 'input' :
            return new ElementRef(getMockHTMLInputElement()) as unknown as ElementRef<T>;
        case 'textarea' :
            return new ElementRef(getMockHTMLTextareaElement()) as unknown as ElementRef<T>;
        default:
            return new MockElementRef() as unknown as ElementRef<T>;
    }
};

export const getMockEvent = (data = {}): Event => ({
    ...{preventDefault: jasmine.createSpy('preventDefault'), target: getMockHTMLElement()},
    ...data,
} as unknown as Event);

export const getMockFileBlob = (file: { name?: string; size?: number; type?: string; webkitRelativePath?: string } = {}): File => ({
    ...{
        name: 'name-file-blob',
        lastModified: 4,
        size: 2,
        type: 'type-file-blob',
    },
    ...file,
} as File);

export const getMockHTMLAnchorElement = (): HTMLAnchorElement => ({
    ...getMockHTMLElement(),
    ...{tagName: HTMLElementTagNameConst.ANCHOR},
} as HTMLAnchorElement);

export const getMockHTMLButtonElement = (): HTMLButtonElement => ({...getMockHTMLElement(), ...{}} as HTMLButtonElement);

export const getMockHTMLCanvasElement = (): HTMLCanvasElement => ({
    ...getMockHTMLElement(),
    ...{
        height: 4,
        offsetHeight: 3,
        offsetWidth: 6,
        ownerDocument: document,
        style: {},
        width: 9,
        getContext: () => getMockCanvasRenderingContext2D(),
    },
} as unknown as HTMLCanvasElement);

export const getMockHTMLDivElement = (): HTMLDivElement => ({...getMockHTMLElement(), ...{}} as HTMLDivElement);

export const getMockHTMLElement = (withParentElement = true, dataParentElement?: HTMLElement): HTMLElement => {
    let parentElement: HTMLElement = undefined!;

    if (withParentElement) {
        parentElement = dataParentElement ?? getMockHTMLElement(false);
    }

    return {
        ...getMockElement(),
        ...{
            classList: {
                add: () => {
                },
            },
            parentElement,
            style: {},
            addEventListener: () => {
            },
            blur: () => {
            },
            click: () => {
            },
            focus: () => {
            },
            remove: () => {
            },
        },
    } as unknown as HTMLElement;
};

export const getMockHTMLImageElement = (data = {}, withParentElement = true): HTMLImageElement => ({
    ...getMockHTMLElement(withParentElement),
    ...{height: 9, width: 16, x: 2},
    ...data,
} as HTMLImageElement);

export const getMockHTMLInputElement = (data = {}, withParentElement = true): HTMLInputElement => ({
    ...getMockHTMLElement(withParentElement),
    ...data,
} as HTMLInputElement);

export const getMockHTMLTextareaElement = (data = {}, withParentElement = true): HTMLTextAreaElement => ({
    ...getMockHTMLElement(withParentElement),
    ...data,
} as HTMLTextAreaElement);

export const getOldMockElementRef = <T>(): ElementRef<T> => ({} as ElementRef<T>);

export const getMockPromise = <T>(returnValue: T): Promise<T> => Promise.resolve<T>(returnValue);

export const getMockPromiseReject = <T>(reason = 'mock-promise-reject'): Promise<T> => Promise.reject<T>(reason);

export const getMockSafeResourceUrl = (): SafeResourceUrl => ({} as SafeResourceUrl);

export const getMockViewContainerRef = (): ViewContainerRef => (new MockViewContainerRef() as unknown as ViewContainerRef);

export class MockDeviceDetectorService {
    isDesktop(): void {
    }
}

export class MockDomSanitizer {
    bypassSecurityTrustResourceUrl(): void {
    }

    sanitize(): void {
    }
}

export class MockLocation {
    path(): void {
    }

    replaceState(): void {
    }
}

export class MockNgZone {
    myObs = new Observable();
    onMicrotaskEmpty = this.myObs;
    onUnstable = this.myObs;

    run(): void {
    }

    runOutsideAngular(): void {
    }
}

export class MockRenderer2 {
    addClass(): void {
    }

    appendChild(): void {
    }

    createElement(): void {
    }

    createText(): void {
    }

    insertBefore(): void {
    }

    listen(): void {
    }

    removeAttribute(): void {
    }

    removeChild(): void {
    }

    removeClass(): void {
    }

    removeStyle(): void {
    }

    setAttribute(): void {
    }

    setStyle(): void {
    }
}

export class MockViewContainerRef {
    element = getOldMockElementRef();

    clear(): void {
    }

    createComponent(): void {
    }

    createEmbeddedView(): void {
    }
}

@Directive({selector: '[ngSrc]'})
export class MockNgSrcDirective {
    @Input() ngSrc!: string;
    @Input() priority!: boolean;
}

@Directive({selector: '[ngPlural]'})
export class MockNgPluralDirective {
    @Input() ngPlural!: number;
    @Input() ngPluralCase!: string;
}
