import {inject, Injectable} from '@angular/core';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgVenteManager} from '@legacy/app/managers/managers';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import Vente from '@models/ventes/vente/vente.model';
import {Observable, of, ReplaySubject, Subject, switchMap} from 'rxjs';
import {filter, map, startWith, take, tap} from 'rxjs/operators';
import {CDossierFilesService} from '@models/dossiers/dossier/files/collection/dossier-files.collection.service';
import {BienService} from '@models/bien/bien.service';
import Etude from '@models/etudes/etude/etude.model';
import {EtudeFactory} from '@models/etudes/etude/etude.factory';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {ModalService} from '@shared/modal/modal.service';
import {
    DCNotestimBiensSelectComponent
} from '@features/notestim/biens/select/dynamic-components/notestim-biens-select.component';
import {
    DCNotestimBienImportComponent
} from '@features/notestim/biens/bien/import/dynamic-components/notestim-bien-import.component';
import NotestimBien from '@models/notestim/biens/bien/notestim-bien.model';
import {TextsService} from '@shared/texts/texts.service';
import {NgVente} from '@legacy/app/managers/ressources';

@Injectable({providedIn: 'root'})
export class VenteService {
    static getPreventChangesQuestion(vente: Vente, priceDifferences: string[]): string {
        const differences = priceDifferences.map(priceDifference => `<li>${priceDifference}</li>`).join('');
        const details = `<ul class="!tw:list-disc !tw:ml-12">${differences}</ul>`;
        const confirmation = `En confirmant ${priceDifferences.length > 1 ? 'ces modifications' : 'cette modification'}`;
        const question = `<div class="tw:my-4">${confirmation}, votre bien en vente ${vente.reference} sera renvoyé automatiquement sur vos diffusions.</div>`;

        return `<div class="tw:text-left">${details}${question}</div>`;
    }

    private _bienService = inject(BienService);
    private _cDossierFilesService = inject(CDossierFilesService);
    private _etudeFactory = inject(EtudeFactory);
    private _etudeService = inject(EtudeService);
    private _modalService = inject(ModalService);
    private _ngInjectorService = inject(NgInjectorService);
    private _textsService = inject(TextsService);
    private _venteFactory = inject(VenteFactory);
    private _currentSource = new ReplaySubject<Vente>(1);
    private _current$ = this._currentSource.asObservable().pipe(startWith(undefined as unknown as Vente));
    private _needToBeSavedSource = new Subject<void>();
    private _needToBeSaved$ = this._needToBeSavedSource.asObservable();

    // Supprimer les injections des anciens manager
    private get ngVenteManager(): NgVenteManager {
        return this._ngInjectorService.getService('VenteManager');
    }

    get current$(): Observable<Vente> {
        return this._current$;
    }

    get needToBeSaved$(): Observable<void> {
        return this._needToBeSaved$;
    }

    askToBeSaved(): void {
        this._needToBeSavedSource.next();
    }

    cloneCurrent$(keepProprietaires: boolean): Observable<Vente> {
        return this.current$.pipe(filter(vente => !!vente), switchMap(vente => this._venteFactory.clone$(vente, keepProprietaires)));
    }

    createVirgin$(): Observable<Vente> {
        const vente = this._venteFactory.createVirgin();

        return this._bienService.createVirgin$().pipe(tap(bien => vente.bien = bien), map(_ => vente));
    }

    getCurrentFromNg(): Vente {
        if (!this.ngVenteManager.current) {
            return undefined!;
        }

        return this._venteFactory.ngCreate(this.ngVenteManager.current);
    }

    getEtude$(vente: Vente): Observable<Etude> {
        return this._etudeFactory.getByLink$(vente.linkEtude);
    }

    importFromNotestim$(): Observable<Vente> {
        return this._modalService.open$<NotestimBien>(DCNotestimBiensSelectComponent, {}, {
            align: 'top',
            withPadding: false
        }).pipe(
            take(1),
            switchMap(notestimBien => {
                if (!notestimBien) {
                    return of(undefined as unknown as Vente);
                }

                return this._modalService.open$<Vente>(DCNotestimBienImportComponent, {notestimBien}, {allowClose: false});
            }),
        );
    }

    initCurrent(idOrUuid: number | string): void {
        this._currentSource.next(undefined!);
        (this._textsService.uuidValidate(idOrUuid.toString()) || !Number.isNaN(idOrUuid) ? this._venteFactory.get$(idOrUuid.toString()) : this.createVirgin$()).pipe(
            tap(vente => this._cDossierFilesService.initCurrentWithMedia(vente)),
            take(1),
        ).subscribe(vente => this._currentSource.next(vente));
    }

    isCurrentPrintAffichettes$(): Observable<boolean> {
        return this.current$.pipe(
            filter(vente => !!vente),
            switchMap(vente => this._etudeService.last$.pipe(
                switchMap(etude => {
                    if (vente.interne) {
                        return of({etude, etudeConfrere: etude});
                    }

                    return this.getEtude$(vente).pipe(map(etudeConfrere => ({etude, etudeConfrere})));
                }),
            )),
            map(({etude, etudeConfrere}) =>
                etude.imprimerAffichetteConfrere && etudeConfrere.imprimerAffichetteConfrere
            ),
        );
    }

    preventChanges$(vente: Vente, initVente: Vente): Observable<boolean> {
        const priceDifferences = vente.getPricingDifferences(initVente);

        if (priceDifferences.length > 0) {
            return this._modalService.openConfirmation$({
                buttonConfirmationLabel: 'Enregistrer',
                checkComments: true,
                comments: `Je confirme ${priceDifferences.length > 1 ? 'les modifications' : 'la modification'}.`,
                question: VenteService.getPreventChangesQuestion(vente, priceDifferences),
                title: `Modification${priceDifferences.length > 1 ? 's relatives' : ' relative'} au prix`,
                status: ModalService.statuts.WARNING,
            });
        }

        return of(true);
    }

    save$(vente: Vente, initVente?: Vente): Observable<Vente> {
        const isNew = vente.isNew();
        let changesAccepted$ = of(true);

        if (initVente) {
            changesAccepted$ = this.preventChanges$(vente, initVente);
        }

        return changesAccepted$.pipe(
            switchMap(isAccepted => {
                if (isAccepted) {
                    return this._venteFactory.save$(vente).pipe(switchMap(venteSaved => {
                        if (!isNew) {
                            return of(venteSaved);
                        }

                        // Si c'est un nouveau bien, on enregistre les collections du bien qui étaient simplement en mémoire
                        return this._bienService.saveCollectionForNew$(vente, venteSaved)
                            .pipe(switchMap(() => this._venteFactory.getByLink$(venteSaved.linkSelf)));
                    }));
                }

                return of(undefined as unknown as Vente);
            })
        );
    }

    ngSave$(ngVente: NgVente, initNgVente?: NgVente): Observable<Vente> {
        const vente = this._venteFactory.ngCreate(ngVente);
        let initVente!: Vente;

        if (initNgVente) {
            initVente = this._venteFactory.ngCreate(initNgVente);
        }

        return this.save$(vente, initVente);
    }
}
