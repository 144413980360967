<div class="tw:py-4 tw:md:p-4 tw:flex-1">
  <div class="tw:form-2col">
    @if (demandeur$ | appLoadObs | async; as demandeur) {
      <div>
        <div class="tw:form-2col-part">
          <div class="tw:form-left-col">
            <h3 class="tw:form-left-col-title">Demandeur</h3>

            <p class="tw:form-left-col-explain">
              @if (!options.fixedDemandeur) {
                Recherchez un demandeur ou un contact à associer par un nom, un téléphone, une adresse email ou une
                référence.<br>Vous ne le trouvez pas ? Créez le et retrouvez le dans votre portefeuille de demandeurs !
              }
            </p>
          </div>

          <div class="tw:form-right-col">
            @if (options.fixedDemandeur) {
              <div class="tw:text-sm tw:text-gray-400">
                Votre demandeur {{demandeur.value ? demandeur.value.reference : ''}} est sélectionné automatiquement.
              </div>
            } @else {
              <form #demandeurCreationForm="ngForm" id="demandeurCreationForm" novalidate>
                <div class="tw:sm:max-w-md tw:flex tw:flex-col tw:gap-2" [appFormError]="demandeursSelect.formInput">
                  <label class="tw:block tw:text-sm tw:text-gray-700">Sélection d'un demandeur</label>

                  <div class="tw:flex tw:space-x-4">
                    <app-demandeurs-select #demandeursSelect="demandeursSelect" [demandeur]="demandeur.value"
                                           [options]="{disabled: demandeurCreation, placeholder: 'Rechercher un demandeur ou un contact', required: true, withContacts: true}"
                                           class="tw:w-[calc(100%_-_68px_-_1rem)] tw:sm:w-[calc(100%_-_175px_-_1rem)] tw:sm:max-w-[calc(100%_-_175px_-_1rem)]"
                                           (selected)="selectDemandeur($event)"/>

                    <button type="button" [disabled]="demandeurCreation" class="tw:btn-secondary-info"
                            (click)="demandeurCreation = true">
                      Créer<span class="tw:hidden tw:sm:inline"> un demandeur</span>
                    </button>
                  </div>
                </div>

                @if (demandeurCreation) {
                  <div class="tw:mt-8 tw:border-t tw:border-gray-200 tw:pt-4 tw:flex tw:flex-col tw:gap-4">
                    <div class="tw:flex tw:items-center">
                      <label class="tw:flex-1 tw:text-center tw:text-lg tw:font-medium tw:text-gray-500">
                        Création d'un demandeur
                      </label>

                      <button type="button" class="tw:btn-tertiary-default" (click)="stopDemandeurCreation()">
                        <fa-icon [icon]="'times'" size="xl"/>
                      </button>
                    </div>

                    <app-person-edit-simple class="tw:block tw:w-full tw:sm:w-[37rem] tw:md:w-[42rem] tw:lg:w-[39rem] tw:xl:w-[37rem] tw:2xl:w-[49rem] tw:mx-auto"
                                            (edited)="createDemandeur($event)"/>
                  </div>
                }
              </form>
            }
          </div>
        </div>
      </div>
    } @else {
      <app-loader [classHeight]="'tw:h-40'" class="tw:block"/>
    }

    @if (dossierBiens$ | async; as dossierBiens) {
      <div>
        <div class="tw:form-2col-part">
          <div class="tw:form-left-col">
            <h3 class="tw:form-left-col-title">Biens</h3>

            <p class="tw:form-left-col-explain">
              @if (options.fixedDossierBiens) {
                Le bien en cours est automatiquement sélectionné.
              } @else {
                Recherchez le ou les biens en vente et/ou en location à associer.<br><br>Le filtre sur les biens
                correspondants n'est actif que pour un demandeur sélectionné avec une recherche.<br>Pour la recherche
                par mots-clés, celle-ci s'effectuera sur les noms, prénoms, téléphones et adresses email (si le bien
                appartient à mon étude) et sur les références, le titre, le descriptif, la rue, le code postal et la
                commune du bien.
              }
            </p>
          </div>

          <div class="tw:form-right-col">
            <div class="tw:flex tw:flex-col tw:gap-2">
              <label class="tw:block tw:text-sm tw:text-gray-700">
                {{dossierBiens.length > 1 ? 'Biens sélectionnés' : 'Bien sélectionné'}}
              </label>

              <ul class="tw:grid tw:grid-cols-1 tw:sm:grid-cols-2 tw:gap-6">
                @for (dossierBien of dossierBiens; track dossierBien) {
                  <li class="tw:shadow-xs tw:rounded-md">
                    <app-dossier-bien-card [dossierBien]="dossierBien" (action)="removeDossierBien($event)"
                                           [options]="{enabledAction: (options.fixedDossierBiens ? undefined! : 'close'), withBorder: true}"/>
                  </li>
                }

                <li class="tw:hidden tw:first:last:flex tw:justify-center tw:items-center tw:shadow-xs tw:rounded-md tw:bg-gray-100 tw:text-lg tw:min-h-[6rem]">
                  Recherchez des biens à associer
                </li>
              </ul>
            </div>

            @if (!options.fixedDossierBiens) {
              <div class="tw:mt-8">
                @if (dossierBiensSearch) {
                  <div class="tw:border-t tw:border-gray-200 tw:pt-4 tw:flex tw:flex-col tw:gap-4">
                    <div class="tw:flex tw:items-center">
                      <label class="tw:flex-1 tw:text-center tw:text-lg tw:font-medium tw:text-gray-500">
                        Recherche et sélection des biens
                      </label>

                      <button type="button" class="tw:btn-tertiary-default" (click)="dossierBiensSearch = false">
                        <fa-icon [icon]="'times'" size="xl"/>
                      </button>
                    </div>

                    <div class="tw:flex tw:flex-col tw:gap-2">
                      <label class="tw:block tw:text-sm tw:text-gray-700">Filtres sur la recherche</label>

                      @if (currentUser$ | async; as currentUser) {
                        <div class="tw:flex tw:flex-wrap tw:gap-4">
                          <app-form-select-button [list]="dossierBiensSources" [model]="dossierBiensSourcesCode"
                                                  (selected)="selectDossierBiensSource($event)"/>

                          @if (currentUser.hasRoleGrpLocation() && currentUser.hasRoleGrpVente()) {
                            <app-form-select-button [list]="dossierBiensTypes" [model]="dossierBiensTypesCode"
                                                    (selected)="selectDossierBiensType($event)"/>
                          }

                          <form class="tw:min-w-[20rem]" novalidate>
                            <app-form-search-input (searchedText)="searchDossierBiens($event)"
                                                   [options]="{placeholder: 'Rechercher un bien'}"/>
                          </form>
                        </div>
                      } @else {
                        <app-loader [classHeight]="'tw:h-10'" class="tw:block"/>
                      }
                    </div>

                    @if (filteredDossierBiens$ | async; as filteredDossierBiens) {
                      <div>
                        <div class="tw:grid-list">
                          @for (dossierBien of filteredDossierBiens.results; track dossierBien) {
                            <div class="tw:grid-list-item">
                              <app-dossier-bien-card [dossierBien]="dossierBien" (action)="addDossierBien($event)"
                                                     [options]="{actionOnAll: true, enabledAction: 'choose'}"/>
                            </div>
                          }
                          <div class="tw:hidden tw:first:col-span-2 tw:first:flex tw:even:flex tw:justify-center tw:items-center tw:text-lg tw:min-h-[7rem]">
                            Modifier vos filtres pour voir plus de dossiers
                          </div>
                        </div>
                      </div>
                    } @else {
                      <app-loader [classHeight]="'tw:h-40'" class="tw:block"/>
                    }
                  </div>
                } @else {
                  <button type="button" class="tw:btn-secondary-info" (click)="displayDossierBiensSearch()">
                    Rechercher<span class="tw:hidden tw:sm:inline"> des biens</span>
                  </button>
                }
              </div>
            }
          </div>
        </div>
      </div>
    } @else {
      <app-loader [classHeight]="'tw:h-40'" class="tw:block"/>
    }

    <div class="tw:form-2col-part">
      <div class="tw:form-left-col"><h3 class="tw:form-left-col-title">Visite</h3></div>

      <form class="tw:form-right-col" #visiteForm="ngForm" id="visiteForm" novalidate>
        <div class="tw:w-48 tw:flex tw:flex-col tw:gap-2" [appFormError]="bonvisiteDate.formInput">
          <label class="tw:block tw:text-sm tw:text-gray-700">Date</label>

          <app-form-select-datetime [model]="bonvisite.dateVisite" (selected)="bonvisite.dateVisite = $event"
                                    [options]="{name: 'bonvisiteDatetime'}" #bonvisiteDate="formSelectDatetime"
                                    class="tw:block"/>
        </div>
      </form>
    </div>
  </div>
</div>
